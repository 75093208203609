import { Link } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import instructionFile from '../data/TDL Throat Nose PCR Sampling.pdf'

const RegistrationConfirmation = () => {
  return (
    <>
      <GatsbySeo title="Registration Confirmation" />

      <section className="section">
        <div className="container is-max-desktop">
          <h2 className="title has-text-midnightBlue is-spaced is-size-4-mobile">
            Thanks for registering! Next Steps:
          </h2>

          <h3 className="subtitle has-text-midnightBlue is-size-6-mobile">Collect your sample</h3>

          <div className="content">
            <p>
              Follow the instructions found in your test kit to collect your sample. If you prefer a digital copy, you
              can download them here:{' '}
              <a href={instructionFile} download="TDL Throat Nose PCR Sampling.pdf">
                PCR swab test instructions.
              </a>
            </p>

            <p>
              This video has some useful tips for taking a nose and throat swab to test for coronavirus. Please note
              that the packaging and labelling are different for your kit but the actual sampling process is the same:
            </p>

            <iframe
              title="Instruction video (Youtube)"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/8lo6g-TYZ-c"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <h3 className="subtitle has-text-midnightBlue is-size-6-mobile">Complete the request form</h3>

          <div className="content">
            <p>
              You must write your full name and date of birth on the request form as well as on the sample collection
              tube. Please make sure to write legibly so the lab staff can read your writing. Without a labelled sample
              and completed request form, the lab cannot analyse your sample.
            </p>
          </div>

          <h3 className="subtitle has-text-midnightBlue is-size-6-mobile">Post your sample</h3>

          <div className="content">
            <p>
              Place your sample and the request form in the prepaid envelope and stick on the Royal Mail 24hr Tracked
              label. Keep a note of your tracking number. Post your sample to our lab as soon as possible after taking
              it. We strongly recommend posting your sample in a Royal Mail Priority postbox. Use the{' '}
              <a href="https://www.royalmail.com/services-near-you" target="_blank" rel="noreferrer noopener">
                Royal Mail service finder{' '}
              </a>
              to check the location of your nearest priority postbox.
            </p>
          </div>

          <h3 className="subtitle has-text-midnightBlue is-size-6-mobile">Get your results</h3>

          <div className="content">
            <p>
              Your result will be available within 48 hours of our receiving your sample in the laboratory. We will
              email it to the address you verified with us.
            </p>
          </div>

          <hr />

          <p className="mt-3">
            <Link to="/register">Register another kit</Link>
          </p>
        </div>
      </section>
    </>
  )
}

export default RegistrationConfirmation
